import { useEffect } from "react";
import SectionHero2 from "components/SectionHero2/SectionHero2";
import like from "images/like.png";
import dance from "images/dance.png";
import home from "images/home.png";
import allston from "images/allston.jpg";
import jamaica from "images/jamaica.jpg";
import missionHill from "images/mission-hill.jpg";
import fenway from "images/fenway.jpg";

import React from "react";
import SectionOurAchievements from "components/SectionOurAchievements/SectionOurAchievements";
import SectionAllInOnePlatform from "components/SectionAllInOnePlatform/SectionAllInOnePlatform";
import SectionWhatMakesUsDifferent from "components/SectionWhatMakesUsDifferent/SectionWhatMakesUsDifferent";
import SectionPopularAreas from "components/SectionPopularAreas/SectionPopularAreas";
import SectionAboutUs from "components/SectionAboutUs/SectionAboutUs";
import { ApiService } from "services/api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useUserAccount } from "providers/UserAccountProvider";
import UserAccountRepository from "data/repositories/UserAccountRepository";
import {
  HttpError,
  HttpErrorStatus,
} from "../../data/models/errors/http-error";
import { plainToClass } from "class-transformer";
import { UserAccount } from "data/models/user-account";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

function PageHome() {
 const navigate = useNavigate();
  const { isAuthenticated, user, getAccessTokenSilently } =
    useAuth0();
  const { setUserAccount } = useUserAccount();

  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;

    if (isAuthenticated && user) {
      getAccessTokenSilently().then(async (token) => {
        ApiService.getInstance().setToken(token);

        try {
          const userAccount =
            await UserAccountRepository.getInstance().getAccountByEmailId(
              user.email!
            );
          setUserAccount(userAccount);
        } catch (e) {
          const HttpError = e as HttpError;
          // if (HttpError.status === HttpErrorStatus.NOT_FOUND) {
          //   // User account not found. Create a new account
          //   const userAccount =
          //     await UserAccountRepository.getInstance().createUserAccount(
          //       plainToClass(UserAccount, user)
          //     );
          //   setUserAccount(userAccount);
          // }
        }
      });
    }

    if (isAuthenticated) {
      navigate("/search-results")
    }

    $body.classList.add("theme-teal-blueGrey");
    return () => {
      $body.classList.remove("theme-teal-blueGrey");
    };
  }, [isAuthenticated]);

  return (
    <div
      className={`nc-ListingStayMapPage relative`}
      data-nc-id="ListingStayMapPage"
    >
      <Helmet><title>Students Landing</title></Helmet>
      <SectionHero2 className="mb-32" />
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">

        {/* SECTION */}
        <SectionOurAchievements
          data={[
            {
              id: 1,
              img: like,
              heading: "2000+",
              desc: "Apartments Tours",
            },
            {
              id: 2,
              img: home,
              heading: "900+",
              desc: "Apartments Rented",
            },
            {
              id: 3,
              img: dance,
              heading: "1500+",
              desc: "Satisfied Renters",
            },
          ]}
        />

        {/* SECTION */}
        <SectionAllInOnePlatform />

        {/* SECTION */}
        <SectionWhatMakesUsDifferent />

        {/* SECTION */}
        {/* <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: "Search",
              desc: "Curated properties that are international student friendly.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "See",
              desc: "Pictures, details and virtual tours of properties.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Sign & Save",
              desc: "Digitally process your lease and avoid paying high broker fees.",
            },
          ]}
        />
        <AppStoreButtons /> */}

        <SectionAboutUs />

        {/* SECTION */}
        {/* <div className="">
          <HeaderFilter
            tabActive={"Boston"}
            subHeading={""}
            tabs={["Boston", "New Jersey (Coming Soon)"]}
            heading={"Curated Listings"}
            onClickTab={() => { }}
          />
        </div>

        <SectionGridHasMap /> */}

        {/* SECTION */}
        <SectionPopularAreas
          tabActive={"Boston"}
          tabs={["Boston", "New Jersey (Coming Soon)"]}
          data={[
            {
              id: 1,
              img: fenway,
              heading: "Fenway",
              noOfProperties: 1332,
            },
            {
              id: 2,
              img: jamaica,
              heading: "Jamaica Plain",
              noOfProperties: 1857,
            },
            {
              id: 3,
              img: allston,
              heading: "Allston",
              noOfProperties: 1665,
            },
            {
              id: 4,
              img: missionHill,
              heading: "Mission Hill",
              noOfProperties: 1115,
            },
          ]}
          onClickTab={() => {}}
        />
      </div>
    </div>
  );
}

export default PageHome;
